<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 text-uppercase">
        <v-icon class="cursor--pointer" @click="backleads">mdi-reply</v-icon>

        {{ "Leads" }}
      </div>
    </div>
    <v-card color="white" elevation="1">
      <v-card-title class="primary white--text" primary-title>
        Import Lead
      </v-card-title>
      <template>
        <v-stepper v-model="e6" vertical>
          <v-stepper-step :complete="e6 > 1" step="1">
            Upload File
            <small>Files format: xls, csv</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-file-input
              v-model="file"
              accept=".xls,.csv"
              show-size
              truncate-length="15"
              label="Select File"
            ></v-file-input>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || file == null"
              @click="firstStep"
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2">
            Headers Preview
            <small>Select files to match</small>
          </v-stepper-step>

          <v-stepper-content step="2">
            <template v-if="headersData.length != 0">
              <v-row class="pa-1">
                <v-col cols="12" sm="6">
                  <v-select
                    :items="headersData"
                    v-model="headers.name"
                    label="Name *"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.last_name"
                    label="Last name"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.phone"
                    label="Phone *"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.email"
                    label="Email"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="sources"
                    v-model="headers.sourceId"
                    label="Source *"
                    item-text="name"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <!--   <v-select
                    :items="headersData"
                    v-model="headers.created_time"
                    label="Created time"
                    outlined
                    clearable
                    dense
                  ></v-select> -->
                  <!--  <v-select
                    :items="headersData"
                    v-model="headers.campaing"
                    label="Campaing"
                    outlined
                    clearable
                    dense
                  ></v-select> -->
                  <!-- <v-select
                    :items="headersData"
                    v-model="headers.howKnow"
                    label="How know"
                    outlined
                    clearable
                    dense
                  ></v-select> -->
                  <!--   <v-select
                    :items="headersData"
                    v-model="headers.status"
                    label="Status"
                    outlined
                    clearable
                    dense
                  ></v-select> -->
                  <v-select
                    :items="headersData"
                    v-model="headers.gender"
                    label="Gender"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <!--  <v-select
                    :items="headersData"
                    v-model="headers.height"
                    label="Height"
                    outlined
                    clearable
                    dense
                  ></v-select> -->
                  <v-select
                    :items="headersData"
                    v-model="headers.birthDate"
                    label="Birth date"
                    outlined
                    clearable
                    dense
                  ></v-select>

                  <v-autocomplete
                    v-model="headers.agentUuid"
                    label="Agent"
                    :items="workersf"
                    :loading="loadingEmployees"
                    :filter="
                      (item, queryText) => {
                        return item.fullname
                          .toLowerCase()
                          .includes(queryText.toLowerCase());
                      }
                    "
                    item-text="fullname"
                    item-value="uuid"
                    autocomplete="off"
                    clearable
                    hide-details
                    outlined
                    dense
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.fullname"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-if="data.item.ocupation"
                          class="text-capitalize"
                          v-html="data.item.ocupation.name"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                          v-if="data.item.phone"
                          class="text-capitalize"
                          v-html="data.item.phone"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <!--  <v-select
                    :items="headersData"
                    v-model="headers.procedureInterest"
                    label="Procedure of interest"
                    outlined
                    clearable
                    dense
                  ></v-select>
                  <v-select
                    :items="headersData"
                    v-model="headers.zipCode"
                    label="Zip code"
                    outlined
                    clearable
                    dense
                  ></v-select> -->
                </v-col>
                <template v-if="firstData != null">
                  <v-col class="d-flex flex-column" cols="12" sm="6">
                    <v-text-field
                      label="Name"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.name)"
                    ></v-text-field>
                    <v-text-field
                      label="Last Name"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.last_name)"
                    ></v-text-field>
                    <v-text-field
                      label="Phone"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.phone)"
                    ></v-text-field>
                    <v-text-field
                      label="Email"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.email)"
                    ></v-text-field>
                    <v-text-field
                      label="Source"
                      disabled
                      outlined
                      dense
                      :value="getDataSource(headers.sourceId)"
                    ></v-text-field>
                    <!-- <v-text-field
                      label="Created time"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.created_time)"
                    ></v-text-field> -->
                    <!-- <v-text-field
                      label="Campaing"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.campaing)"
                    ></v-text-field> -->
                    <!--    <v-text-field
                      label="How know"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.howKnow)"
                    ></v-text-field> -->
                    <!--  <v-text-field
                      label="Status"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.status)"
                    ></v-text-field> -->
                    <v-text-field
                      label="Gender"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.gender)"
                    ></v-text-field>
                    <!--   <v-text-field
                      label="Height"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.height)"
                    ></v-text-field> -->
                    <v-text-field
                      label="Birth date"
                      disabled
                      outlined
                      dense
                      :value="getDataDOB(headers.birthDate)"
                    ></v-text-field>
                    <v-text-field
                      label="Agent"
                      disabled
                      outlined
                      dense
                      :value="getDataAgent(headers.agentUuid)"
                    ></v-text-field>
                    <!--  <v-text-field
                      label="Procedure of interest"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.procedureInterest)"
                    ></v-text-field>
                    <v-text-field
                      label="Zip code"
                      disabled
                      outlined
                      dense
                      :value="getData(headers.zipCode)"
                    ></v-text-field> -->
                  </v-col>
                </template>
              </v-row>
              <v-alert outlined type="error" :value="!validMatch">
                Fields with (*) are required
              </v-alert>
            </template>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || !validMatch"
              @click="secondStep"
            >
              Match
            </v-btn>
            <v-btn text @click="cancelStep"> Cancel </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3">
            Data Preview
            <small>Examples: first and last element </small>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-row>
              <v-col cols="12" sm="6">
                <span class="text-decoration-underline font-weight-bold"
                  >First</span
                >
                <template v-if="firstResult">
                  <div class="d-flex flex-column">
                    <div
                      class="my-1"
                      v-for="(value, key, index) in firstResult"
                      :key="index"
                    >
                      <strong>{{ key }}:</strong> {{ value }}
                    </div>
                  </div>
                </template>
              </v-col>
              <v-col cols="12" sm="6">
                <span class="text-decoration-underline font-weight-bold"
                  >Last</span
                >
                <template v-if="lastResult">
                  <div class="d-flex flex-column">
                    <div
                      class="my-1"
                      v-for="(value, key, index) in lastResult"
                      :key="index"
                    >
                      <strong>{{ key }}:</strong> {{ value }}
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-btn
              color="info"
              class="mr-1"
              :loading="loading"
              :disabled="loading"
              @click="previewLastStep"
            >
              Preview
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="lastStep"
            >
              Confirm
            </v-btn>
            <v-btn text @click="cancelStep"> Cancel </v-btn>

            <v-dialog
              v-model="dialogPreview"
              :overlay="false"
              max-width="900px"
              transition="dialog-transition"
            >
              <v-card>
                <v-card-title class="primary white--text" primary-title>
                  Preview Results
                </v-card-title>
                <v-card-text class="mt-1 bodycard">
                  <template v-if="existingData.length != 0">
                    <div class="text-center">Existing</div>
                    <v-data-table
                      :headers="headersPreview"
                      :items="existingData"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.isMale`]="{ item }">
                        <span>{{ item.isMale ? "Male" : "Female" }} </span>
                      </template>
                      <template v-slot:[`item.sourceNom`]="{ item }">
                        <span
                          >{{ item.sourceNom ? item.sourceNom.name : "-" }}
                        </span>
                      </template>
                    </v-data-table>
                  </template>
                  <template v-if="insertedData.length != 0">
                    <div class="text-center">Inserted</div>
                    <v-data-table
                      :headers="headersPreview"
                      :items="insertedData"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.isMale`]="{ item }">
                        <span>{{ item.isMale ? "Male" : "Female" }} </span>
                      </template>
                      <template v-slot:[`item.sourceNom`]="{ item }">
                        <span
                          >{{ item.sourceNom ? item.sourceNom.name : "-" }}
                        </span>
                      </template>
                    </v-data-table>
                  </template>
                  <template v-if="leadNewStatusesData.length != 0">
                    <div class="text-center">Lead New Statuses</div>
                    <v-data-table
                      :headers="headersPreview"
                      :items="leadNewStatusesData"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.isMale`]="{ item }">
                        <span>{{ item.isMale ? "Male" : "Female" }} </span>
                      </template>
                      <template v-slot:[`item.sourceNom`]="{ item }">
                        <span
                          >{{ item.sourceNom ? item.sourceNom.name : "-" }}
                        </span>
                      </template>
                    </v-data-table>
                  </template>
                  <template v-if="repeatonFileData.length != 0">
                    <div class="text-center">Repeat on File</div>
                    <v-data-table
                      :headers="headersPreview"
                      :items="repeatonFileData"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.isMale`]="{ item }">
                        <span>{{ item.isMale ? "Male" : "Female" }} </span>
                      </template>
                      <template v-slot:[`item.sourceNom`]="{ item }">
                        <span
                          >{{ item.sourceNom ? item.sourceNom.name : "-" }}
                        </span>
                      </template>
                    </v-data-table>
                  </template>
                  <template v-if="withError.length != 0">
                    <div class="text-center">With Errors</div>
                    <v-data-table
                      :headers="headersPreview"
                      :items="withError"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.isMale`]="{ item }">
                        <span>{{ item.isMale ? "Male" : "Female" }} </span>
                      </template>
                      <template v-slot:[`item.sourceNom`]="{ item }">
                        <span
                          >{{ item.sourceNom ? item.sourceNom.name : "-" }}
                        </span>
                      </template>
                    </v-data-table>
                  </template>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-stepper-content>

          <v-stepper-step step="4" :complete="e6 == 4">
            Import successful
          </v-stepper-step>
          <v-stepper-content step="4">
            <div class="warning ma-1">
              <strong>Existing:</strong>
              <span>{{ existsLeads.length }}</span>
            </div>
            <div class="ma-1 success">
              <strong>Inserted:</strong>
              <span>{{ insertedLeads.length }}</span>
            </div>

            <v-btn color="primary" @click="resetAll"
              ><v-icon class="mr-1">mdi-cloud-upload</v-icon> import another
            </v-btn>
            <v-btn class="mx-2" color="warning" @click="backleads">
              <v-icon>mdi-exit-to-app</v-icon> Exit
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </template>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import cleanObject from "@/utils/cleanObject";
import { notifyError } from "@/components/Notification";
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  name: "import-lead",
  data() {
    return {
      e6: 1,
      file: null,
      loading: false,
      sources: [],
      loadingSources: false,
      dialogPreview: false,
      headers: {
        name: null,
        last_name: null,
        phone: null,
        email: null,
        /* created_time: null,
        campaing: null,
        howKnow: null, */
        /* status: null, */
        gender: null,
        /*  height: null, */
        birthDate: null,
        /* procedureInterest: null,
        zipCode: null, */
        agentUuid: null,
        sourceId: null,
      },
      firstData: null,
      firstResult: null,
      configResult: null,
      lastResult: null,
      existsLeads: [],
      insertedLeads: [],
      headersData: [],
      headersPreview: [
        { text: "Name", value: "name", sortable: false },
        { text: "Last Name", value: "last_name", sortable: false },
        { text: "DOB", value: "birthDate", sortable: false },
        { text: "Gender", value: "isMale", sortable: false },
        { text: "Phone", value: "phone", sortable: false },
        { text: "Id", value: "id", sortable: false },
        { text: "Source", value: "sourceNom", sortable: false },
      ],
      existingData: [],
      insertedData: [],
      repeatonFileData: [],
      leadNewStatusesData: [],
      withError: [],
    };
  },
  watch: {
    e6(val) {
      //
    },
  },
  computed: {
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),
    validMatch() {
      if (
        this.headers.name != null &&
        this.headers.phone != null &&
        this.headers.sourceId != null
      ) {
        return true;
      }
      return false;
    },
    workersf() {
      const w = this.employees.filter((e) => e.phone != null && e.phone != "");
      return w;
    },
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    backleads() {
      this.$router.push("/leads_center");
    },
    getData(value) {
      return this.firstData[value];
    },
    getDataSource(value) {
      const x = this.sources.find((s) => s.id == value);
      return x ? x.name : "-";
    },
    getDataDOB(value) {
      return value ? moment(this.firstData[value]).format("MM/DD/YYYY") : "";
    },
    getDataAgent(value) {
      const w = this.workersf.find((w) => w.uuid == value);
      return w ? w.fullname : "";
    },

    listSources() {
      this.loadingSources = true;

      getAPI(`/source/list`)
        .then((res) => {
          this.sources = res.data;

          this.loadingSources = false;
        })
        .catch((error) => {
          this.loadingSources = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    firstStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      getAPI
        .post("/social/decodeFilePreview", formDatafile)
        .then((res) => {
          this.loading = false;
          this.headersData = res.data.headers;
          this.firstData = res.data.first;
          this.e6 = 2;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    secondStep() {
      this.loading = true;
      let body = Object.assign({}, this.headers);
      body.sourceId = Number(body.sourceId);
      body = cleanObject(body);
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      Object.entries(body).forEach(([key, value]) => {
        formDatafile.append(key, value);
      });
      getAPI
        .post("/social/matcheLeadsPreview", formDatafile)
        .then((res) => {
          this.loading = false;
          this.firstResult = res.data.first;
          this.lastResult = res.data.last;
          this.configResult = res.data.config;
          this.e6 = 3;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    lastStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      Object.entries(this.configResult).forEach(([key, value]) => {
        formDatafile.append(key, value);
      });
      getAPI
        .post("/social/insertLeadsFromfile", formDatafile)
        .then((res) => {
          this.loading = false;
          this.existsLeads = res.data.existing;
          this.insertedLeads = res.data.inserted;
          this.e6 = 4;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    previewLastStep() {
      this.loading = true;
      const formDatafile = new FormData();

      formDatafile.append("file", this.file);

      Object.entries(this.configResult).forEach(([key, value]) => {
        formDatafile.append(key, value);
      });
      getAPI
        .post("/social/insertLeadsFromfile", formDatafile, {
          params: { preview: true },
        })
        .then((res) => {
          const response = res.data;
          this.loading = false;
          this.dialogPreview = true;
          this.existingData = response.existing;
          this.repeatonFileData = response.repeatedOnFile;
          this.withError = response.withError;
          this.insertedData = response.inserted;
          this.leadNewStatusesData = response.leadNewStatuses;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    resetStepHeaders() {
      this.firstData = null;
      this.headersData = [];
      this.headers = {
        name: null,
        last_name: null,
        phone: null,
        email: null,
        /*  created_time: null,
        campaing: null,
        howKnow: null, */
        /* status: null,
        weight: null,
        height: null, */
        gender: null,
        birthDate: null,
        agentUuid: null,
        sourceId: null,
        /*  procedureInterest: null,
        zipCode: null, */
      };
    },

    resetAll() {
      this.e6 = 1;
      this.file = null;
      this.loading = false;

      this.headers = {
        name: null,
        last_name: null,
        phone: null,
        email: null,
        /* created_time: null, */
        /* campaing: null,
        howKnow: null,
        status: null,
        weight: null,
        height: null, */
        gender: null,
        birthDate: null,
        /*  procedureInterest: null,
        zipCode: null, */
        agentUuid: null,
        sourceId: null,
      };
      this.firstData = null;
      this.firstResult = null;
      this.configResult = null;
      this.lastResult = null;
      this.existsLeads = [];
      this.insertedLeads = [];
      this.headersData = [];
    },
    cancelStep() {
      if (this.e6 == 2) {
        this.resetStepHeaders();
      } else if (this.e6 == 3) {
        this.firstResult = null;
        this.lastResult = null;
        this.configResult = null;
      }
      this.e6 = this.e6 - 1;
    },
  },
  mounted() {
    this.actGetEmployees();
    this.listSources();
  },
};
</script>
<style lang="scss" scoped>
.bodycard {
  max-height: 1000px;
  overflow-y: auto;
}
</style>
